import React, { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { colors } from 'src/theme/colors'
import {CategoryContainerContainer} from './styles'

function CategoriesSkeletonComponent() {
  const array = new Array(10).fill(10)
  return (
    <CategoryContainerContainer>
      <div className="content">
        {array.map((_, index) => (
          <div key={index}>
            <Skeleton width={130} height={80} baseColor={colors.palette.neutral500} />
          </div>
        ))}
      </div>
      <div className="content">
        {array.map((_, index) => (
          <div key={index}>
            <Skeleton width={130} height={78} baseColor={colors.palette.neutral500} />
          </div>
        ))}
      </div>
  </CategoryContainerContainer>
)
}

export const CategoriesSkeleton = memo(CategoriesSkeletonComponent)
