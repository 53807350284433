import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import TitleWrapper from "src/components/Common/TitleWrapper";
import {Title} from "src/components/Common";
import {colors} from "src/theme/colors";
import {IoCloseSharp} from "react-icons/io5";

interface IProps {
  statusCode: number
}

const Error: FC<IProps> = ({ statusCode }) => {
  const { t } = useTranslation()
  return (
    <>
      <TitleWrapper>
        <Title
          borderMargin="medium"
          size="lg"
          borderColor={colors.palette.white}
          tx="404"
          Icon={<IoCloseSharp fontSize={36} />}
          hasIcon
          hasBorder={false}
          theme="light"
        />
        <Title
          borderMargin="medium"
          size="lg"
          borderColor={colors.palette.white}
          tx={t(`error.${statusCode}`)}
          hasBorder={false}
          theme="light"
        />
      </TitleWrapper>
    </>
  )
}

export default Error
