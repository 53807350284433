import React from 'react'
import { NextPage } from 'next'
import Error from 'src/components/Error'
import PublicLayout from 'src/components/Layout/public'
import { CategoriesApi } from 'src/service/api/api.types'
import { CategoryMenu } from 'src/components/Common/CategoryMenu'
import { CategoryContainer } from 'src/components/Search/styles'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/store/rootReducers'
import { setCategoriesRequest } from 'src/store/category'
import {CategoriesSkeleton} from "src/components/Skeleton/categoriesCard";

interface Props {
  categories: CategoriesApi
}

const Error404Page: NextPage<Props> = () => {
  const dispatch = useDispatch()
  const categories = useSelector((state: State) => state.category.categories)
  const isLoading = useSelector((state: State) => state.category.isLoading)

  React.useEffect(() => {
    ;(async () => {
      await fetchCategory()
    })()
  }, [])

  async function fetchCategory() {
    dispatch(setCategoriesRequest())
  }

  return (
    <>
      <PublicLayout showCategory={false}>
        <CategoryContainer>
          <Error statusCode={404} />
          {!isLoading && categories ? <CategoryMenu categories={categories} preset="page404" /> : <CategoriesSkeleton />}
        </CategoryContainer>
      </PublicLayout>
    </>
  )
}

export default Error404Page
